import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Alert,
  Container,
  Input,
  Label,
  Form,
  FormFeedback,
  InputGroup,
  InputGroupText
} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
import { registerUser, apiError } from "../../store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link, useNavigate } from "react-router-dom";
import logo_maaia from "../../assets/images/companies/img-3.png";
import { triggerBillingWebhookFunction } from "../../helpers/api_helper";
import { withTranslation } from "react-i18next";
import withRouter from "../../components/Common/withRouter";
import LanguageDropdown from "../../components/Common/TopbarDropdown/LanguageDropdown";

const RegisterTrial = (props) => {
  document.title = "Register Trial | Synibox - Smart Assistant";
  const [decompte, setDecompte] = useState(5);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      password_confirm: "",
      password: "",
      cgv_accept: false
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please enter your email"),
      password: Yup.string().required("Veuillez saisir votre mot de passe"),
      // password_confirm: Yup.string().required('Veuillez confirmer votre mot de passe'),
      password_confirm: Yup.string()
        .required("Veuillez confirmer votre mot de passe")
        .test(
          "passwords-match",
          "Les mots de passe ne correspondent pas",
          function (value) {
            return this.parent.password === value;
          }
        ),
      cgv_accept: Yup.boolean()
        .oneOf(
          [true],
          "Vous devez accepter nos conditions générales d'utilisation"
        )
        .required("Vous devez accepter nos conditions générales d'utilisation")
    }),
    onSubmit: (values) => {
      dispatch(registerUser(values));
      validation.resetForm();
    }
  });

  const { user, registrationError, loading } = useSelector((state) => ({
    user: state.account.user,
    registrationError: state.account.registrationError,
    loading: state.account.loading
  }));

  // handleValidSubmit
  const handleValidSubmit = (values) => {
    dispatch(registerUser(values));
  };

  const [isPasswordHidden, setIsPasswordHidden] = useState(true);
  const togglePasswordVisibility = () => {
    setIsPasswordHidden(!isPasswordHidden);
  };

  useEffect(() => {
    dispatch(apiError(""));
  }, [dispatch]);

  useEffect(() => {
    document.body.className = "bg-pattern";
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  });

  function fetchActiveSection() {
    const authUser = localStorage.getItem("authUser");
    if (authUser !== null) {
      navigate(`/dashboard`, {
        replace: true
      });
    }
  }

  useEffect(() => {
    fetchActiveSection();
  });

  useEffect(() => {
    if (decompte === 0) {
      navigate(`/login`, {
        replace: true
      });
    }
  }, [decompte]);

  const triggerBillingWebhook = async (id) => {
    const data = {
      type: "no_cc_trial",
      direct_plan: "direct_world",
      offered_credits: 750,
      id
    };
    try {
      const result = await triggerBillingWebhookFunction(data);
      console.log(result);
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    if (user) {
      triggerBillingWebhook(user.$id);
      let interval;
      interval = setInterval(() => {
        setDecompte((prevDecompte) => prevDecompte - 1);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [user]);

  const svgs = {
    eye_icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className=""
        style={{ width: "24px" }}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
        />
      </svg>
    ),
    eye_slash_icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className=""
        style={{ width: "24px" }}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88"
        />
      </svg>
    )
  };

  function openTermsOfUse() {
    return localStorage.getItem("I18N_LANGUAGE") === "en"
      ? "https://smartas.semecloud.tech/terms-of-use"
      : "https://smartas.semecloud.tech/fr/terms-of-use";
  }

  return (
    <div
      className="account-pages d-flex align-items-center"
      style={{ minHeight: "100vh", overflow: "hidden" }}
    >
      <Container fluid={true}>
        <Row className="justify-content-center mb-3">
          <Col lg={12} md={12} xl={10} xxl={10} className="d-flex">
            <div
              className="font-primary ms-auto px-2"
              style={{
                backgroundColor: "white",
                height: "fit-content",
                borderRadius: "10px",
                boxShadow: "0px 6px 24px 1px rgba(53, 22, 252, 0.1)"
              }}
            >
              <LanguageDropdown hasArrow />
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center m-0">
          <Col lg={12} md={12} xl={10} xxl={10}>
            <div className="auth-card">
              <Row>
                <div className="auth-card__left col-xl-6 d-flex justify-content-center">
                  <img src={logo_maaia} alt="logo_maaia" height={100} />
                </div>
                <div className="login-card__right col-xl-6 pt-xl-5 pb-xl-5">
                  <Form
                    className="form-horizontal col-10"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <div className="text-center py-3">
                      <Label
                        className="font-primary"
                        style={{ textAlign: "center", fontSize: "18px" }}
                      >
                        {props.t("INSCRIPTION")}
                      </Label>
                    </div>

                    {user && user ? (
                      <Alert className="text-center" color="success">
                        {props.t(
                          "Votre compte est créé. Vous serez redirigé dans "
                        )}
                        {decompte}
                        {props.t("sec")}...
                      </Alert>
                    ) : null}

                    {registrationError && registrationError ? (
                      <Alert color="danger">
                        <div className="font-primary">{registrationError}</div>
                      </Alert>
                    ) : null}

                    <Row>
                      <Col md={12}>
                        <div className="mb-4">
                          <Label className="form-label font-primary">
                            {props.t("E-mail")}
                          </Label>
                          <Input
                            id="email"
                            name="email"
                            className="form-control"
                            placeholder={props.t("Entrer votre e-mail")}
                            type="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email &&
                              validation.errors.email
                                ? true
                                : false
                            }
                          />
                          {validation.touched.email &&
                          validation.errors.email ? (
                            <FormFeedback type="invalid">
                              <div className="font-primary">
                                {props.t(validation.errors.email)}
                              </div>
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-4">
                          <Label className="form-label font-primary">
                            {props.t("Mot de passe")}
                          </Label>
                          <InputGroup>
                            <Input
                              name="password"
                              type={isPasswordHidden ? "password" : "text"}
                              placeholder={props.t("Entrer Mot de passe")}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.password || ""}
                              invalid={
                                validation.touched.password &&
                                validation.errors.password
                                  ? true
                                  : false
                              }
                            />
                            <InputGroupText
                              style={{
                                backgroundColor: "transparent",
                                border: "none",
                                padding: "0"
                              }}
                            >
                              <button
                                className="btn h-100 form-control"
                                type="button"
                                onClick={togglePasswordVisibility}
                                style={{
                                  borderTopLeftRadius: "0",
                                  borderBottomLeftRadius: "0"
                                }}
                              >
                                {isPasswordHidden
                                  ? svgs.eye_icon
                                  : svgs.eye_slash_icon}
                              </button>
                            </InputGroupText>
                            {validation.touched.password &&
                            validation.errors.password ? (
                              <FormFeedback type="invalid">
                                <div className="font-primary">
                                  {props.t(validation.errors.password)}
                                </div>
                              </FormFeedback>
                            ) : null}
                          </InputGroup>
                        </div>
                        <div className="mb-4">
                          <Label className="form-label font-primary">
                            {props.t("Confirmation Mot de passe")}
                          </Label>
                          <InputGroup>
                            <Input
                              name="password_confirm"
                              placeholder={props.t("Confirmer Mot de passe")}
                              type={isPasswordHidden ? "password" : "text"}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.password_confirm || ""}
                              invalid={
                                validation.touched.password_confirm &&
                                validation.errors.password_confirm
                                  ? true
                                  : false
                              }
                            />
                            <InputGroupText
                              style={{
                                backgroundColor: "transparent",
                                border: "none",
                                padding: "0"
                              }}
                            >
                              <button
                                className="btn h-100 form-control"
                                type="button"
                                onClick={togglePasswordVisibility}
                                style={{
                                  borderTopLeftRadius: "0",
                                  borderBottomLeftRadius: "0"
                                }}
                              >
                                {isPasswordHidden
                                  ? svgs.eye_icon
                                  : svgs.eye_slash_icon}
                              </button>
                            </InputGroupText>
                            {validation.touched.password_confirm &&
                            validation.errors.password_confirm ? (
                              <FormFeedback type="invalid">
                                <div className="font-primary">
                                  {props.t(validation.errors.password_confirm)}
                                </div>
                              </FormFeedback>
                            ) : null}
                          </InputGroup>
                        </div>
                        <Row className="align-items-center justify-content-between">
                          <Col className="col-auto">
                            <div className="form-check font-primary">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="term-conditionCheck"
                                // checked={validation.values.cgv_accept}
                                value={validation.values.cgv_accept}
                                name="cgv_accept"
                                onChange={validation.handleChange}
                                //  invalid={validation.touched.cgv_accept === false && validation.errors.cgv_accept ? true : false}
                              />
                              <label
                                className="form-label fw-normal font-primary ms-1 mt-1 mb-0"
                                htmlFor="term-conditionCheck"
                              >
                                {props.t("J'accepte les")}
                                <Link
                                  to={openTermsOfUse()}
                                  target="_blank"
                                  className="ms-1 text-primary font-primary"
                                >
                                  {props.t("Termes et Conditions")}
                                </Link>
                              </label>
                              {validation.touched.cgv_accept &&
                              validation.errors.cgv_accept ? (
                                <div type="invalid">
                                  <div
                                    style={{ color: "red" }}
                                    className="font-primary"
                                  >
                                    {props.t(validation.errors.cgv_accept)}
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          </Col>
                          <Col className="col-xl-auto">
                            <button
                              className="btn btn-primary waves-effect waves-light font-primary"
                              style={{
                                width: "100%",
                                backgroundColor: "#FF7049",
                                border: "none",
                                color: "#FFF",
                                borderRadius: "10px",

                                fontSize: "17.005px",
                                fontStyle: "normal",
                                fontWeight: "500",
                                lineHeight: "29.152px",
                                boxShadow:
                                  "0px 19.683547973632812px 45.92827606201172px rgba(255, 112, 73, 0.20)"
                              }}
                              type="submit"
                            >
                              {loading && (
                                <span
                                  className="spinner-border spinner-border-sm me-2 font-primary"
                                  role="status"
                                  aria-hidden="true"
                                />
                              )}
                              {props.t("Inscription")}
                            </button>
                          </Col>
                        </Row>
                        <Row className="align-items-center justify-content-between mt-4 font-primary">
                          <p
                            style={{
                              color: " #737791",

                              fontSize: "15px",
                              fontStyle: "normal",
                              fontWeight: "400",
                              lineHeight: "normal"
                            }}
                            className=""
                          >
                            {props.t("Déjà un compte ?")}{" "}
                            <Link
                              to="/login"
                              className="fw-medium text-primary font-primary"
                            >
                              {props.t("Connexion")}
                            </Link>
                          </p>
                        </Row>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default withRouter(withTranslation()(RegisterTrial));
