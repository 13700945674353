import React, { useState, useEffect } from "react";
import {
  FormFeedback,
  FormGroup,
  Input,
  UncontrolledTooltip,
  Spinner
} from "reactstrap";
import {
  //createAgentFunctionExecution,
  //  createStatisticsFunction,
  createLog,
  klaviyoUpdateProfile,
  fetchAgentInfos,
  startAgent,
  stopAgent,
  launchServer
} from "../../helpers/api_helper";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchClientInfos,
  isActivatedAgentStatus,
  isAgentActiveLoading
} from "../../store/actions";
import { useMixpanel } from "../../Hooks/useMixpanel";
import { Client, Databases } from "appwrite";
import { withTranslation } from "react-i18next";

const MaaiaChangeStatus = (props) => {
  //  const [client_data, setCompanyInfos] = useState(undefined);
  const dispatch = useDispatch();

  // const [numberAgentActive, setNumberAgentActive] = useState(false);
  // const [agentActive, setAgentActive] = useState(undefined);
  const { mixPanelTrack } = useMixpanel();

  const {
    client_data,
    number_of_credits,
    is_user_plan_active,
    agentActive,
    numberAgentActive
  } = useSelector((state) => {
    const theCompany_infos = state.GeneralStateRedux.client_data;
    let agentState = false;
    let agents;
    let agent;
    if (theCompany_infos) {
      agents = theCompany_infos.agents.map((element) => JSON.parse(element));

      if (agents && agents.length) {
        agent = agents[0];
        // for (const agent of agents) {
        //   try {
        //     if (agent) {
        //       agentState = true;
        //       break;
        //     }
        //   } catch (erreur) {
        //     // console.error("Erreur lors de la requête:", erreur.message);
        //   }
        // }
        agentState = true;
      } else {
        agentState = false;
      }
    }

    return {
      is_user_plan_active: state.subscription.isUserPlanActive,
      number_of_credits: state.subscription.subscriptionCredits,
      consumed_email_count: state.GeneralStateRedux.consumed_email_count,
      client_data: state.GeneralStateRedux.client_data,
      agentActive: agent,
      numberAgentActive: agentState
    };
  });
  const { agentStatus, activeLoading } = useSelector((state) => ({
    agentStatus: state.GeneralStateRedux.agentStatus,
    activeLoading: state.GeneralStateRedux.activeLoading
  }));
  // const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (client_data) {
      getAgentInfos(client_data);
    } else {
      getClientData();
    }
  }, []);

  const getClientData = async function () {
    const client = new Client();

    const databases = new Databases(client, "default");

    client
      .setEndpoint(process.env.REACT_APP_DATABASEURL)
      .setProject(process.env.REACT_APP_PROJECTID);

    const promise = databases.getDocument(
      "default",
      "client_infos",
      localStorage.getItem("companyId")
    );

    promise.then(
      function (response) {
        getAgentInfos(response);
        return response;
      },
      function (error) {
        console.log(error); // Failure
        return null;
      }
    );
  };

  // useEffect(() => {
  //   if (client_data && number_of_credits && is_user_plan_active) {
  //     checkAIAgent();
  //     checkStatistics();
  //   }
  // }, [client_data, number_of_credits]);

  // useEffect(() => {
  //   if (numberAgentActive === undefined) {
  //     checkAgentStatus();
  //   }
  // }, [agentStatus]);

  // const checkAgentStatus = async () => {
  //   if (client_data) {
  //     const agents = client_data.agents.map((element) => JSON.parse(element));
  //     console.log({ agents: agents });

  //     let agentState = false;
  //     if (agents && agents.length) {
  //       for (const agent of agents) {
  //         try {
  //           if (agent.active) {
  //             agentState = true;
  //             break;
  //           }
  //         } catch (erreur) {
  //           // console.error("Erreur lors de la requête:", erreur.message);
  //         }
  //       }
  //     } else {
  //       agentState = undefined;
  //     }
  //     setAgentActive(agents[0]);
  //     setNumberAgentActive(agentState);
  //   }
  // };

  async function updateCompanyInfos(data) {
    console.log("Update agent active: ", data);
    const client = new Client();

    const databases = new Databases(client, "default");

    client
      .setEndpoint(process.env.REACT_APP_DATABASEURL)
      .setProject(process.env.REACT_APP_PROJECTID);

    const promise = databases.updateDocument(
      "default",
      "client_infos",
      localStorage.getItem("companyId"),
      JSON.stringify(data)
    );

    return promise.then(
      async function (response) {
        console.log("updateCompanyInfos success", response); // Success
        return response;
      },
      function (error) {
        console.log("updateCompanyInfos error", error); // Failure
        return undefined;
      }
    );
  }

  const agentStatusChangeActions = async (old_val) => {
    dispatch(isAgentActiveLoading(true));
    const updatedAgentList = [
      JSON.stringify({
        ...agentActive,
        active: !old_val
      })
    ];

    console.log({ updatedAgentList });

    const response = await updateCompanyInfos({
      agents: updatedAgentList
    });

    // const response = old_val === false ? await startAgent(agentActive.id) : await stopAgent(agentActive.id);

    // console.log({ startStopAgent: response });

    if (response) {
      dispatch(fetchClientInfos());
      // setAgentActive({
      //   ...agentActive,
      //   active: !old_val
      // });
      // setNumberAgentActive(!old_val);

      // Créer le log
      const company_id = localStorage.getItem("companyId");
      var user_id = JSON.parse(localStorage.getItem("authUser")).userId;

      const updates = {
        properties: {
          agent_active: !old_val
        }
      };
      klaviyoUpdateProfile(company_id, updates);

      let logData = {
        eventCreatedAt: Date.now(),
        author_id: user_id,
        company_id: company_id,
        agent_id: agentActive?.id
      };
      let logdata = {
        event: old_val ? "agent_disabled" : "agent_enabled",
        author_id: user_id,
        company_id: company_id,
        log_data: JSON.stringify(logData)
      };
      createLog(logdata, mixPanelTrack);

      dispatch(isActivatedAgentStatus(!old_val));
    } else {
      // await startAgent(agentActive.id);
    }
    // else {
    //   await launchServer(agentActive.id);
    // }
    dispatch(isAgentActiveLoading(false));
  };

  async function getAgentInfos(clientResponse) {
    try {
      const agent = clientResponse
        ? JSON.parse(clientResponse.agents[0])
        : undefined;

      if (agent && agent.active === true) {
        dispatch(isActivatedAgentStatus(true));
      } else {
        dispatch(isActivatedAgentStatus(false));
      }
    } catch (error) {
      console.log({ fetchAgentInfos: error });
      // if (error && error.includes("404")) {
      // launchServer(agentActive.id);
      dispatch(isActivatedAgentStatus(false));
      // }
    }
  }

  return (
    <div>
      {/* {numberAgentActive !== undefined && ( */}
      <div className="d-flex align-items-center gap-2 header__service_status__switch">
        {agentStatus ? props.t("Actif") : props.t("Inactif")}
        <FormGroup
          id="UncontrolledTooltipServiceStatus"
          switch
          className="d-flex mb-0"
          style={{ position: "relative" }}
        >
          {activeLoading === true ? (
            <div style={{ textAlign: "center", fontSize: "14px" }}>
              <Spinner
                className="me-1"
                style={{ height: "15px", width: "15px" }}
                animation="border"
              />
            </div>
          ) : (
            <Input
              id="service-status-switch"
              type="switch"
              disabled={
                client_data &&
                (!agentActive ||
                  !agentActive.agent_timezone ||
                  !client_data.shopify_token ||
                  !client_data.shopify_store_url ||
                  !client_data.company_website_url)
              }
              className="my-0"
              onClick={() => {
                agentStatusChangeActions(agentStatus);
              }}
              checked={agentStatus}
              defaultChecked={agentStatus}
              style={{
                width: "35px",
                height: "22.5px",
                backgroundColor: agentStatus ? "#05B714" : "#FFA500",
                borderColor: agentStatus ? "#05B714" : "#FFA500",
                boxShadow: "none",
                cursor:
                  client_data &&
                  (!agentActive ||
                    !agentActive.agent_timezone ||
                    !client_data.shopify_token ||
                    !client_data.shopify_store_url ||
                    !client_data.company_website_url)
                    ? "not-allowed"
                    : "pointer"
              }}
            />
          )}
        </FormGroup>
      </div>
      {client_data &&
      (!client_data.shopify_token || !client_data.shopify_store_url) ? (
        <UncontrolledTooltip
          innerClassName="font-primary"
          placement="right"
          target="UncontrolledTooltipServiceStatus"
        >
          {props.t(
            "Votre CMS n'est pas connecté. Veuillez le connecter pour activer les fonctionnalités de Synibox."
          )}
        </UncontrolledTooltip>
      ) : client_data && !client_data.company_website_url ? (
        <UncontrolledTooltip
          innerClassName="font-primary"
          placement="right"
          target="UncontrolledTooltipServiceStatus"
        >
          {props.t(
            "Votre nom de domaine n'est pas ajouté. Veuillez l'ajouter pour activer les fonctionnalités de Synibox."
          )}
        </UncontrolledTooltip>
      ) : client_data && (!agentActive || !agentActive.agent_timezone) ? (
        <UncontrolledTooltip
          innerClassName="font-primary"
          placement="right"
          target="UncontrolledTooltipServiceStatus"
        >
          {props.t(
            "Le fuseau horaire de Synibox n'est pas réglé. Veuillez le régler."
          )}
        </UncontrolledTooltip>
      ) : null}

      {/* )} */}
    </div>
  );
};

export default withTranslation()(MaaiaChangeStatus);
