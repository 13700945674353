import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import {
  Card,
  CardBody,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Spinner
} from "reactstrap";
import CheckCircleRounded from "../../assets/images/icons/CheckCircleRounded";
import { useFacebook } from "react-facebook";
import {
  fetchFBAccessToken,
  fetchFBDisconnectPageWebhook,
  fetchFBDisconnectUser,
  fetchFBGetSubscribedApps,
  fetchFBPagesList,
  fetchFBSubscribeToWebhook,
  updateUserData
} from "../../helpers/api_helper";
import CrossOutlined from "../../assets/images/icons/CrossOutlined";
import CustomToast from "../../components/Common/customToast/CustomToast";
import { Client, Databases } from "appwrite";

function FacebookSettings(props) {
  const [fbAuthLoading, setFBAuthLoading] = useState(false);
  const [isLogoutLoading, setIsLogoutLoading] = useState(false);
  const [toastMessage, setToastMessage] = useState({});
  const [toast, setToast] = useState(false);
  const [pageDropdown, setPageDropdown] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState({});
  const [isFbConnected, setIsFbConnected] = useState(undefined);
  const [fb_selected_pages, setFBSelectedPages] = useState([]);
  const [fb_pages, setFBPages] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [isGlobalLoading, setIsGlobalLoading] = useState(true);

  const { init } = useFacebook();

  const onFBAuthLoadingChanged = (val) => {
    setFBAuthLoading(val);
  };

  const onGlobalLoadingChanged = (val) => {
    setIsGlobalLoading(val);
  };

  async function handleLogin() {
    const api = await init();

    // const response = await api.login();
    const FB = await api.getFB(); // Get original FB object

    FB.login(
      function (response) {
        console.log(response);
        if (response.authResponse) {
          handleFBLoginSuccess(response.authResponse);
        }
      },
      {
        config_id: "575923421469247",
        response_type: "code",
        override_default_response_type: true
      }
    );
  }

  async function handleFBLoginSuccess(auth) {
    onFBAuthLoadingChanged(true);
    const code = auth.code;

    try {
      const access_token_response = await fetchFBAccessToken(code);

      await updateUserData(localStorage.getItem("companyId"), {
        fb_user_id: access_token_response.user_id,
        fb_access_token: access_token_response.access_token
      });

      const response = await fetchFBPagesList(
        access_token_response.access_token,
        access_token_response.user_id
      );

      setIsFbConnected(true);
      setFBPages(
        response.pages.map((page) => {
          return {
            id: page.id,
            name: page.name,
            category: page.category,
            access_token: page.access_token
          };
        })
      );
      onFBAuthLoadingChanged(false);
    } catch (error) {
      onFBAuthLoadingChanged(false);
    }
  }

  const handleAddFBPage = async function (page) {
    setAddLoading(true);
    const updated_pages_id = [...fb_selected_pages.map((p) => p.id), page.id];
    const res = await updateUserData(localStorage.getItem("companyId"), {
      fb_pages_id: updated_pages_id
    });
    const result = await fetchFBSubscribeToWebhook(page.id, page.access_token);

    if (!res) {
      setAddLoading(false);
      return;
    }

    setFBSelectedPages([...fb_selected_pages, page]);
    setAddLoading(false);
  };

  const handleRemoveFBPage = async function (page) {
    setDeleteLoading({ ...deleteLoading, [page.id]: true });
    const updated_pages_id = fb_selected_pages
      .filter((p) => p.id !== page.id)
      .map((p) => p.id);
    const res = await updateUserData(localStorage.getItem("companyId"), {
      fb_pages_id: updated_pages_id
    });
    const result = await fetchFBDisconnectPageWebhook(
      page.id,
      page.access_token
    );

    if (!res) {
      setDeleteLoading({ ...deleteLoading, [page.id]: false });
      return;
    }
    setFBSelectedPages(fb_selected_pages.filter((p) => p.id !== page.id));
    setDeleteLoading({ ...deleteLoading, [page.id]: false });
  };

  async function handleLogout(connected_pages) {
    setIsLogoutLoading(true);
    try {
      const response = await fetchFBDisconnectUser(
        localStorage.getItem("companyId"),
        JSON.stringify(connected_pages)
      );
      console.log({ logoutResponse: response });
      setIsFbConnected(false);
      setFBSelectedPages([]);
      setIsLogoutLoading(false);
      setToastMessage({
        header: props.t("Succès"),
        body: props.t("Déconnexion effectuée"),
        key: "success"
      });
    } catch (error) {
      console.log({ logoutResponse: error });
      setIsLogoutLoading(false);
      setToastMessage({
        header: props.t("Echec"),
        body: error.message,
        key: "danger"
      });
    }
  }

  const toggleToast = () => {
    console.log("setToast before: " + toast);
    setToast(true);
    console.log("setToast after: " + toast);

    setTimeout(() => {
      setToast(false);
    }, 3000);
  };

  function onFbConnected(val) {
    setIsFbConnected(val);
  }

  async function initFbConfig(clientInfos) {
    if (clientInfos.fb_access_token) {
      onFbConnected(true);
      setLoading(true);
      const res = await fetchFBPagesList(
        clientInfos.fb_access_token,
        clientInfos.fb_user_id
      );
      var FBSelectedPages = [];

      if (res) {
        const FBPages = res.pages.map((page) => {
          return {
            id: page.id,
            name: page.name,
            category: page.category,
            access_token: page.access_token
          };
        });
        setFBPages(FBPages);
        console.log({
          FBPages
        });

        FBSelectedPages = res.pages
          .filter((page) => clientInfos.fb_pages_id.includes(page.id))
          .map((page) => {
            return {
              id: page.id,
              name: page.name,
              category: page.category,
              access_token: page.access_token
            };
          });

        setFBSelectedPages(FBSelectedPages);
      }
      setLoading(false);

      console.log({
        FBSelectedPages
      });
    } else {
      onFbConnected(false);
    }
  }

  const getClientData = async function () {
    onGlobalLoadingChanged(true);
    const client = new Client();
    const databases = new Databases(client, "default");

    client
      .setEndpoint(process.env.REACT_APP_DATABASEURL)
      .setProject(process.env.REACT_APP_PROJECTID);

    try {
      const promise = databases.getDocument(
        "default",
        "client_infos",
        localStorage.getItem("companyId")
      );

      promise.then(
        async function (response) {
          console.log("getClientData", response);
          const clientInfos = response;
          initFbConfig(clientInfos);
          onGlobalLoadingChanged(false);
        },
        function (error) {
          onGlobalLoadingChanged(false);
          console.log(error);
          return null;
        }
      );
    } catch (error) {
      console.log(error);
      onGlobalLoadingChanged(false);
    }
  };

  useEffect(() => {
    getClientData();
  }, []);

  return (
    <div className="row mx-auto col-xxl-9 col-xl-11 mt-3">
      <div
        className="position-fixed bottom-0 end-0 p-3"
        style={{ zIndex: "9999999" }}
      >
        <CustomToast
          isOpen={toast}
          toggle={toggleToast}
          message={toastMessage}
        />
      </div>

      {isGlobalLoading === true ? (
        <div style={{ textAlign: "center", fontSize: "14px" }}>
          <Spinner
            className="me-1"
            style={{ height: "15px", width: "15px" }}
            animation="border"
          />
          {props.t("Chargement") + "..."}
        </div>
      ) : (
        <>
          <div
            style={{
              border: "1px solid",
              borderColor: "#E9ECEF",
              borderRadius: "10px",
              padding: "20px",
              margin: "0 auto"
            }}
          >
            <div
              style={{
                fontWeight: "600",
                fontSize: "18px",
                color: isFbConnected === true ? "black" : "#8590a5"
              }}
            >
              1. {props.t("Authentifiez")}
            </div>
            {isFbConnected === true ? (
              <div className="mt-2">
                {props.t("Connexion effectuée")}{" "}
                <span style={{ color: "#563BFF" }}>
                  <CheckCircleRounded width={24} height={24} />
                </span>
                <div
                  className="d-flex align-items-center"
                  style={{
                    marginTop: "10px",
                    border: "1px solid",
                    borderColor: "#E9ECEF",
                    borderRadius: "10px",
                    padding: "5px 10px 5px 10px",
                    cursor: "pointer",
                    width: "fit-content",
                    backgroundColor: "#f6f6f6"
                  }}
                  onClick={() => {
                    // handleLogout(fb_selected_pages);
                    fetchFBGetSubscribedApps(
                      fb_selected_pages[0].id,
                      fb_selected_pages[0].access_token
                    );
                  }}
                >
                  <CrossOutlined
                    width={22}
                    height={22}
                    style={{ color: "red" }}
                  />{" "}
                  {props.t("Déconnexion")}
                  {isLogoutLoading && (
                    <span
                      className="spinner-border spinner-border-sm ms-2 font-primary"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                </div>
              </div>
            ) : (
              <div
                className="d-flex align-items-center"
                style={{
                  border: "1px solid",
                  borderColor: "#E9ECEF",
                  borderRadius: "10px",
                  padding: "5px 10px 5px 10px",
                  marginTop: "20px",
                  cursor: "pointer",
                  width: "fit-content"
                }}
                onClick={handleLogin}
              >
                <i
                  className="mdi mdi-facebook me-1"
                  style={{ fontSize: "22px", color: "#563BFF" }}
                />{" "}
                {props.t("Authentification")}
                {fbAuthLoading && (
                  <span
                    className="spinner-border spinner-border-sm ms-2 font-primary"
                    role="status"
                    aria-hidden="true"
                  />
                )}
              </div>
            )}
          </div>

          <div
            style={{
              border: "1px solid",
              borderColor: "#E9ECEF",
              borderRadius: "10px",
              padding: "20px",
              marginTop: "40px",
              color: isFbConnected === true ? "black" : "#8590a5"
            }}
          >
            <div
              style={{
                fontWeight: "600",
                marginBottom: "10px",
                fontSize: "18px"
              }}
            >
              2. {props.t("Connectez une page Facebook")}
            </div>
            <div>
              {props.t("Choisissez une page pour la gestion automatique")}
            </div>
            <div>
              <div className="d-flex gap-3 align-items-center mt-3">
                <label
                  className="font-primary mb-0"
                  style={{
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "600",
                    lineHeight: "normal",
                    color: isFbConnected === true ? "black" : "#8590a5"
                  }}
                >
                  {props.t("Votre Page")} :
                </label>
                <Dropdown
                  isOpen={pageDropdown}
                  toggle={() => {
                    if (isFbConnected === true) {
                      setPageDropdown(!pageDropdown);
                    }
                  }}
                >
                  <div className="d-flex align-items-center">
                    <DropdownToggle
                      tag="button"
                      className="form-control py-0"
                      style={{ textTransform: "capitalize" }}
                    >
                      Sélectionner
                      <i className="ms-2 mdi mdi-chevron-down" />
                    </DropdownToggle>
                  </div>

                  {fb_pages &&
                  fb_pages.filter(
                    (page) =>
                      !fb_selected_pages.map((p) => p.id).includes(page.id)
                  ).length > 0 ? (
                    <DropdownMenu className="dropdown-menu-lg-end">
                      {fb_pages
                        .filter(
                          (page) =>
                            !fb_selected_pages
                              .map((p) => p.id)
                              .includes(page.id)
                        )
                        .map((page) => (
                          <DropdownItem
                            key={page.id}
                            onClick={() => {
                              handleAddFBPage(page);
                            }}
                          >
                            <i
                              className="mdi mdi-facebook me-1"
                              style={{ fontSize: "18px" }}
                            />{" "}
                            {page.name}
                          </DropdownItem>
                        ))}
                    </DropdownMenu>
                  ) : (
                    <DropdownMenu className="dropdown-menu-lg-end">
                      <DropdownItem disabled={true}>
                        {props.t("Vide")}
                      </DropdownItem>
                    </DropdownMenu>
                  )}
                </Dropdown>
                {addLoading && (
                  <span
                    className="spinner-border spinner-border-sm ms-2 font-primary"
                    role="status"
                    aria-hidden="true"
                  />
                )}
              </div>
              <div
                className="mt-3 mb-1"
                style={{
                  fontWeight: "600",
                  marginBottom: "10px",
                  fontSize: "14px",
                  color: "rgb(86, 59, 255)"
                }}
              >
                <i
                  className="mdi mdi-facebook me-1"
                  style={{ fontSize: "18px" }}
                />{" "}
                {props.t("Pages choisies")}
              </div>
              {fb_selected_pages && fb_selected_pages.length > 0 ? (
                <div
                  className="d-flex ps-4"
                  style={{ flexDirection: "column", width: "80%" }}
                >
                  {fb_selected_pages.map((page) => (
                    <Card
                      className="user-card font-primary"
                      style={{
                        borderLeft: "5px solid #563BFF",
                        marginBottom: "12px",
                        borderRadius: "10px",
                        boxShadow: "0px 6px 10px 1px rgba(53, 22, 252, 0.1)",
                        paddingTop: "6px",
                        paddingRight: "29px",
                        paddingBottom: "10px",
                        paddingLeft: "16px",
                        color: "black"
                      }}
                    >
                      <CardBody className="p-0">
                        <Row>
                          <Col
                            md={5}
                            className="d-flex align-items-center"
                            style={{ fontWeight: "600" }}
                          >
                            {page.name}
                          </Col>
                          <Col
                            md={5}
                            className="d-flex align-items-center"
                            style={{ fontWeight: "600" }}
                          >
                            {page.category}
                          </Col>
                          <Col md={2} className="align-items-center">
                            <button
                              onClick={(e) => {
                                handleRemoveFBPage(page);
                              }}
                              className="p-0"
                              style={{
                                background: "none",
                                border: "none",
                                color: "#F10944",
                                float: "inline-end"
                              }}
                            >
                              {deleteLoading[page.id] ? (
                                <span
                                  className="spinner-border spinner-border-sm ms-2 font-primary"
                                  role="status"
                                  aria-hidden="true"
                                />
                              ) : (
                                <CrossOutlined width={20} height={20} />
                              )}
                            </button>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  ))}
                </div>
              ) : loading ? (
                <div
                  className="d-flex justify-content-center"
                  style={{ width: "80%" }}
                >
                  <span
                    className="spinner-border spinner-border-sm mx-auto font-primary"
                    role="status"
                    aria-hidden="true"
                  />
                </div>
              ) : (
                <div className="text-center">{props.t("Vide")}</div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default withTranslation()(FacebookSettings);
